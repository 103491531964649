<template>
  <div>
    <div class="">
      <b-modal v-model="isTemplateListPopupVisible" hide-header hide-footer modal-class="modal-primary modal" centered size="lg">
        <div class="p-1">

          <!-- Popup container -->
          <div class="">
            <div class="popup-header">Send Email</div>

            <!-- Template dropdown -->
            <div class="form-row">
              <label for="templateDropdown">Template</label>
              <select id="templateDropdown" name="template" v-model="selectedTemplate">
                <option value="">-- Select a Template --</option>
                <option v-for="template in templates" :key="template.id" :value="template.id">
                  {{ template.name }}
                </option>
              </select>
              <p v-if="templateError" class="text-danger">{{ templateError }}</p> <!-- Display error message -->

            </div>

            <!-- Subject field -->
            <div class="form-row">
              <label for="subjectField">Subject</label>
              <input type="text" id="subjectField" v-model="subject" placeholder="Subject auto-filled from template" />
            </div>

            <!-- Body (rich text) -->
            <div class="form-row">
              <label for="bodyEn">Body English</label>
              <!-- For a real rich text editor, you’d integrate a WYSIWYG component like Quill, CKEditor, etc. -->
              <rich-editor v-model="bodyEn" id="bodyEn"></rich-editor>
            </div>

            <!-- Body (rich text) -->
            <div class="form-row">
              <label for="bodyAr">Body Arabic</label>
              <!-- For a real rich text editor, you’d integrate a WYSIWYG component like Quill, CKEditor, etc. -->
              <rich-editor v-model="bodyAr" id="bodyAr"></rich-editor>
            </div>

            <!-- From dropdown -->
            <div class="form-row">
              <label for="fromField">From</label>
              <select id="fromField" name="from" v-model="emailfrom">
                <option value="info@bainkom.com">info@bainkom.com</option>
                <option value="rawan@bainkom.com">rawan@bainkom.com</option>
                <option value="araz@bainkom.com">araz@bainkom.com</option>
              </select>

            </div>

            <!-- To field (candidate’s email) -->
            <div class="form-row">
              <label for="toField">To</label>
              <input type="text" v-model="emailto" id="toField" name="to" value="candidate@example.com" readonly />
            </div>

            <!-- Action buttons -->
            <div class="button-row">
              <button type="button" @click="closeTemplateDetailsPopup">Cancel</button>
              <button type="button" @click="sendEmail">Send</button>
            </div>

          </div>
        </div>
      </b-modal>


    </div>

    <b-card v-if="application" class="p-2">
      <!--More Info Modal -->
      <b-modal ref="more-info-modal" ok-only hide-footer modal-class="modal-primary" centered
        title="Request More Information" >
        <form @submit.prevent="openTemplateListPopup('sendmoreinfo')">
          <b-form-textarea rows="8" placeholder="Add any additional document or information required from the applicant"
            class="mb-2" v-model="moreInfoData">

          </b-form-textarea>

          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="!moreInfoData">
              Send
            </b-button>
          </div>
        </form>
      </b-modal>
      <!-- /More Info Modal -->

      <!-- Rejection Modal -->
      <b-modal ref="rejection-modal" ok-only hide-footer modal-class="modal-primary" centered title="Reject"
        >
        <form @submit.prevent="openTemplateListPopup('reject')">
          <b-form-textarea rows="8" placeholder="Please add rejection reason(s)" class="mb-2"
            v-model="rejectionReasons">

          </b-form-textarea>

          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="!rejectionReasons">
              Save
            </b-button>
          </div>
        </form>
      </b-modal>
      <!-- /Rejection Modal -->

      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <h4>
          {{ `${application.details.firstName} ${application.details.lastName}` }}

          <b-badge variant="info" class="badge-glow ml-1">
            {{ application.statusInfo.description }}
          </b-badge>
          <br><br>
          <p v-if="application.statusInfo.code =='Rejected'"><span style="font-weight:400"> Reason : {{application.details.statusReason}}</span></p>
          
        </h4>
<br>

        <div>
          <b-button v-if="allowedAction('Reject')" variant="danger" class="mr-2"
            @click="$refs['rejection-modal'].show()">
            Final Rejection
          </b-button>
          <b-button v-if="allowedAction('Accept')" variant="success" @click="openTemplateListPopup('approve')">
            Final Approval
          </b-button>
        </div>
      </div>

      <hr>

      <div class="basic-details pt-2 mb-5">
        <b-row>
          <b-col md="6" xl="4" class="mb-4">
            <i class="fa-solid fa-briefcase mr-1"></i>
            <span>{{ application.details.profession.text }}</span>
          </b-col>

          <b-col md="6" xl="4" class="mb-4">
            <i class="fa-solid fa-envelope mr-1"></i>
            <span>{{ application.details.emailAddress }}</span>
          </b-col>

          <b-col md="6" xl="4" class="mb-4">
            <i class="fa-solid fa-phone mr-1"></i>
            <span>{{ application.details.cellPhone }}</span>
          </b-col>

          <b-col md="6" xl="4" class="mb-4">
            <i class="fa-solid fa-globe mr-1"></i>
            <span>{{ application.details.country ? application.details.country.name : 'Country Not added' }}</span>
          </b-col>

          <b-col md="6" xl="4" class="mb-4">
            <i class="fa-brands fa-linkedin mr-1"></i>
            <span>{{ application.details.linkedInUrl ? application.details.linkedInUrl : 'Not added' }}</span>
          </b-col>

          <b-col md="6" xl="4">
            <i class="fa-solid fa-link mr-1"></i>
            <span>{{ application.details.websiteUrl ? application.details.websiteUrl : 'Not added' }}</span>
          </b-col>
        </b-row>
        <hr>

        <div class="mb-5 pt-2">
          <h4 class="mb-2">Documents</h4>
          <b-row>
            <b-col md="6" xl="4">
              <div class="d-flex align-items-center border mr-lg-5 p-3">
                <i class="fa-regular fa-file fa-2x"></i>

                <div class="ml-2">
                  <h6 class="mb-0">Resume</h6>
                </div>

                <div v-if="application.details.resumeUrl" class="ml-auto">
                  <b-button variant="flat-info" class="btn-icon" :href="application.details.resumeUrl" target="_blank">
                    <feather-icon icon="DownloadCloudIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>

            <b-col md="6" xl="4">
              <div class="d-flex align-items-center border mr-lg-5 p-3">
                <i class="fa-regular fa-file fa-2x"></i>

                <div class="ml-2">
                  <h6 class="mb-0">Portfolio</h6>
                </div>

                <div v-if="application.details.portfolioUrl" class="ml-auto">
                  <b-button variant="flat-info" class="btn-icon" :href="application.details.portfolioUrl"
                    target="_blank">
                    <feather-icon icon="DownloadCloudIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>

            <b-col md="6" xl="4">
              <div class="d-flex align-items-center border mr-lg-5 p-3">
                <i class="fa-regular fa-file fa-2x"></i>

                <div class="ml-2">
                  <h6 class="mb-0">Cover Letter</h6>
                </div>

                <div v-if="application.details.coverLetterUrl" class="ml-auto">
                  <b-button variant="flat-info" class="btn-icon" :href="application.details.coverLetterUrl"
                    target="_blank">
                    <feather-icon icon="DownloadCloudIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="application.moreInformationItems && application.moreInformationItems.length" class="pt-2">
          <hr class="mb-3">

          <h4 class="mb-2">Additional Information</h4>

          <div v-for="infoItem in application.moreInformationItems" :key="infoItem.id" class="row mb-4 pt-2">
            <div class="col-md-8">
              <b-card class="p-2" style="background: #fafafa">
                <div class="d-flex">
                  <h6 class="mb-4">Request: {{ infoItem.request }}</h6>
                  <span class="text-primary ml-auto">{{ infoItem.status }}</span>
                </div>

                <b-form-textarea class="form-control mb-5" placeholder="Additional Info" rows="8"
                  v-model="infoItem.response" disabled="">
                </b-form-textarea>

                <div v-if="infoItem.files">
                  <hr class="mb-3">
                  <h6 class="mb-3">Additional Documents</h6>
                  <div v-for="file in infoItem.files" :key="file.id" class="d-flex mb-2">
                    <i class="fa-solid fa-link mr-1"></i>
                    <small>{{ file.url }}</small>
                    <b-button variant="flat-info" class="btn-icon ml-auto p-0" :href="file.url" target="_blank">
                      <feather-icon icon="DownloadCloudIcon" />
                    </b-button>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>

        <div class="actions d-flex justify-content-end pt-2">
          <b-button v-if="allowedAction('Ask for more information')" variant="primary" class="mr-2"
            @click="$refs['more-info-modal'].show()">
            Need More Information
          </b-button>
          <b-button v-if="allowedAction('Ask for HR interview')" variant="outline-primary" class="mr-2"
            @click="openTemplateListPopup('hr')">
            Ask for HR Interview
          </b-button>
          <b-button v-if="allowedAction('Ask for technical Interview')" variant="info"  @click="openTemplateListPopup('tech')">
            Ask for Technical Interview
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormTextarea, BRow, BCol, BFormGroup, BButton, BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import richEditor from '../../components/ckEditor/richEditor.vue'

export default {
  props: ['applicationId'],

  components: {
    BBadge,
    BCard,
    BFormTextarea,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
    richEditor
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      application: null,
      moreInfoData: null,
      rejectionReasons: null,
      isTemplateListPopupVisible: false,  // Controls visibility of the template list popup
      templates: [],  // To store the fetched templates
      selectedTemplate: '',
      subject: '', // Subject field
      bodyEn: '',
      bodyAr: '',
      emailfrom: '',
      emailto: '',
      emailType: '' ,
      templateError: '', 
    }
  },

  watch: {
    // Watch for changes in the selected template
    selectedTemplate(newTemplateId) {
      const selected = this.templates.find(template => template.id === newTemplateId);
      if (selected) {
        this.templateError='';
        this.subject = selected.subject;
        this.bodyEn = selected.bodyEn;
        this.bodyAr = selected.bodyAr;
        this.emailfrom = selected.emailFrom;
      } else {
        this.subject = '';
        this.bodyEn = '';
        this.bodyAr= '';
        this.emailFrom = '';
      }
    },
  },

  methods: {
    async fetchTemplates() {
      try {
        // Make an API call to fetch templates from the backend (example endpoint)
        const response = await this.$http.get('EmailTemplates', {});

        // Assuming response.data contains an array of templates
        this.templates = response.data.results;  // Store the templates in the data
        console.log(this.templates);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    },

    async loadApplication() {
      try {
        const response = await this.$http.get(`professionals/${this.applicationId}`);
        this.application = response.data;
      } catch (error) {
      }
    },

    async sendEmail() {
      if (!this.selectedTemplate) {
    this.templateError = 'Please select a template before sending.';
    return;
  }
  this.templateError = ''; 
      let endpoint = '';
      console.log(this.emailType );
      if (this.emailType === 'hr') {
      endpoint = `professionals/${this.applicationId}/askforhrinterview`;
    } else if (this.emailType === 'tech') {
      endpoint = `professionals/${this.applicationId}/askfortechnicalinterview`;
    }else if (this.emailType ==='approve')
    {
      endpoint = `professionals/${this.applicationId}/accept`;
    }else if (this.emailType ==='reject')
    {
      endpoint= (`professionals/${this.applicationId}/reject`);
    }else if (this.emailType ==='sendmoreinfo')
    {
      endpoint= (`professionals/${this.applicationId}/askformoreinformation`);
    }

    try {
      await this.$http.put(
        endpoint,
        {
          bodyEn: this.bodyEn,
          bodyAr: this.bodyAr,
          subject: this.subject,
          emailFrom: this.emailfrom,
          request: this.moreInfoData,
          reason: this.rejectionReasons
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      await this.loadApplication();
      this.closeTemplateDetailsPopup();
      this.showToast('Your request has been sent.');
      this.moreInfoData=null;
      this.rejectionReasons=null;
      this.emailFrom='';
    } catch (error) {
      console.error('Error sending email:', error);
    }
    },

    // async askForTechInterview() {
    //   try {
    //     await this.$http.put(`professionals/${this.applicationId}/askfortechnicalinterview`);

    //     await this.loadApplication();

    //     this.showToast('Your request has been sent.')
    //   } catch (error) {
    //   }
    // },

    // async reject() {
    //   try {
    //     await this.$http.put(`professionals/${this.applicationId}/reject`, {
    //       reason: this.rejectionReasons
    //     });

    //     this.$refs['rejection-modal'].hide();

    //     await this.loadApplication();

    //     this.showToast('Your request has been sent.')
    //   } catch (error) {
    //   }
    // },

    // async approve() {
    //   try {
    //     await this.$http.put(`professionals/${this.applicationId}/accept`);

    //     this.$refs['rejection-modal'].hide();

    //     await this.loadApplication();

    //     this.showToast('Your request has been sent.')
    //   } catch (error) {
    //   }
    // },

    allowedAction(action) {
      return this.application.allowedActions && this.application.allowedActions.includes(action);
    },

    showToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    selectTemplate(template) {
      this.selectedTemplate = template;  // Set the selected template
      this.isTemplateListPopupVisible = false;  // Close the template list popup
      this.isTemplateDetailsPopupVisible = true;  // Open the template details popup
    },

    // Method to close the template details popup
    closeTemplateDetailsPopup() {
      this.isTemplateListPopupVisible = false;
      this.clearModal();
    },
    openTemplateListPopup(type) {
      this.isTemplateListPopupVisible = true;
      this.emailType = type;
      this.emailto = this.application.details.emailAddress;
      this.clearModal();
      
    },

    clearModal()
    {
      this.selectedTemplate = '';
      this.emailFrom='';
      this.$refs['rejection-modal'].hide();
      this.$refs['more-info-modal'].hide();
    }
  },

  async created() {
    await this.loadApplication();
    this.fetchTemplates();
  }
}
</script>

<style >

.popup-container {
  width: 700px !important;
  margin: 50px auto;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

.popup-header {
  font-size: 1.2em;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.form-row {
  margin-bottom: 10px;
}

.form-row label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-row select,
.form-row input[type="text"],
.form-row textarea {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
}

.button-row {
  text-align: right;
  margin-top: 20px;
}

.button-row button {
  padding: 8px 15px;
  margin-left: 10px;
}
</style>
